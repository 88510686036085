import { select, selectAll } from 'd3-selection';
import { scaleLinear } from 'd3-scale';
import { area, line, curveCatmullRom } from 'd3-shape';
import { axisBottom, axisRight} from 'd3-axis';
import { zoom } from 'd3-zoom';

/**
 * Class to get width and extended width of parent SVG element.
 *
 * @param {String} element Class name of element.
 * @param {Array} data List of graph elements.
 * @returns {number} Width of element.
 */
export class Width {
    constructor(element, data) {
        this.element = element;
        this.data = data;
    }

    get() {
        return Number(select(this.element).style('width').replace('px', ''));
    }

    getExtended() {
        let width = Number(select(this.element).style('width').replace('px', ''));
        let factor = 1;
        let newWidth;

        if (width <= 1024 && width > 768) {
            factor = 2;
        } else if (width <= 768) {
            factor = 4;
        }

        if (this.data.length >= 12) {
            newWidth = width * 2 * factor;
        } else if (this.data.length <= 6 && width <= 768) {
            newWidth = width / 4 * factor;
        } else {
            newWidth = width;
        }

        return newWidth;
    }
}

/**
 * Function to get height of parent SVG element.
 *
 * @param {String} element Class name of element.
 * @returns {number} Height of element.
 */
export function getHeight(element) {
    return Number(select(element).style('height').replace('px', ''));
}

/**
 * Function to get x point coordinate.
 *
 * @param {Array} data List of graph elements.
 * @param {Number} width Width of element.
 * @returns d3 scaleLinear function.
 */
export function getX(data, width) {
    return scaleLinear().domain([0, data.length - 1]).range([0, width]);
}

/**
 * Function to get y point coordinate.
 *
 * @param {Number} height Height of element.
 * @returns d3 scaleLinear function.
 */
export function getY(height) {
    return scaleLinear().domain([0, 100]).range([height, 0]);
}

/**
 * Function to set d3 area path.
 *
 * @param {Array} data List of graph elements.
 * @param {Number} width Width of element.
 * @param {Number} height Height of element.
 * @returns d3 area function.
 */
export function setArea(data, width, height) {
    return area()
        .x(function(d, i) {
            return getX(data, width)(i);
        })
        .y0(height)
        .y1(function(d) {
            return getY(height)(d.value);
        })
        .curve(curveCatmullRom.alpha(0));
}

/**
 * Function to set d3 area line.
 *
 * @param {Array} data List of graph elements.
 * @param {Number} width Width of element.
 * @param {Number} height Height of element.
 * @returns d3 line function.
 */
export function setPath(data, width, height) {
    return line()
        .x(function(d, i) {
            return getX(data, width)(i);
        })
        .y(function(d) {
            return getY(height)(d.value);
        })
        .curve(curveCatmullRom.alpha(0));
}

/**
 * Class to get started values of data.
 *
 * @param {Array} data List of graph elements.
 * @returns {Array} List with zeros data values.
 */
export class GetStartData {
    constructor(data) {
        this.data = data;
    }

    getData() {
        return this.data.map( function( newData ) {
            return {
                name  : newData.name,
                value : 0
            };
        });
    }
}

/**
 * Function to set y right axis.
 *
 * @param {Number} height Height of element.
 * @returns d3 axisRight.
 */
export function setYAxis(height) {
    return axisRight()
        .scale(getY(height))
        .ticks(5)
        .tickSizeOuter(0)
        .tickFormat(function(d) {
            return d + ' %';
        });
}

/**
 * Function to set x bottom axis.
 *
 * @param {Array} data List of graph elements.
 * @param {Number} width Height of element.
 * @returns d3 axisBottom.
 */
export function setXAxis(data, width) {
    let xAxisRange = scaleLinear()
        .domain([0, data.length - 1])
        .range([0, width]);

    return axisBottom()
        .scale(xAxisRange)
        .ticks(data.length - 1)
        .tickFormat(function(d) {
            return data[d].name;
        });
}

/**
 * Function to remove first and last element by class name.
 *
 * @param {String} element Element class name.
 */
export function removeElements(element) {
    select(element).remove();
    selectAll(`${element}:last-of-type`).remove();
}

/**
 * Function to set d3 zoom.
 *
 * @param fn Function witch call on zoom event.
 * @returns d3 zoom.
 */
export function getZoom(fn) {
    return zoom()
        .on('zoom', fn);
}

/**
 * Function to get children element position relative to parent,
 *
 * @param {String} parent Class name of parent element.
 * @param {String} children Class name of children element.
 * @returns {Object} Object with offset parameters.
 */
export function getPosition(parent, children) {
    let parentElement = select(parent).node().getBoundingClientRect();
    let childrenElement = select(children).node().getBoundingClientRect();

    return {
        top: childrenElement.top - parentElement.top,
        bottom: childrenElement.bottom - parentElement.bottom,
        left: childrenElement.left - parentElement.left,
        right: childrenElement.right - parentElement.right
    };
}

/**
 * Function to get element transform.
 *
 * @param {String} element Class name of element.
 * @returns {Array} Array with numbers of x, y element transform.
 */
export function getTransform(element) {
    let transform = select(element).attr('transform');
    let transformArray = transform.substring(transform.indexOf("(") + 1, transform.indexOf(")")).split(",");
    return [Number(transformArray[0]), Number(transformArray[1])];
}