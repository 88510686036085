import {
    Svg,
    PathLine,
    PathArea,
    Circles,
    YAxis,
    XAxis,
    Lines,
    ShadowRect,
    Tooltips,
    setHover
} from './libs/drawElements';
import { getGradient, getFilter } from './libs/visualization';
import { json } from 'd3-request';
import { select } from 'd3-selection';

const draw = Symbol();
const setVisualization = Symbol();
const updateData = Symbol();
const prepareData = Symbol();

export class Graph {
    constructor(container, data, options) {
        this.container = container;
        this.data = data;
        this.elements = ['pathLine', 'pathArea', 'lines', 'yAxis', 'xAxis', 'circles', 'tooltips'];
        this.activeElements = [];
        this.options = {
            removeElements: options.removeElements || null,
            hoverActiveElements: options.hoverActiveElements || ['lines', 'xAxis', 'circles'],
            clickActiveElements: options.clickActiveElements || ['lines', 'circles'],
            pathBgGradations: options.pathBgGradations || ['0%', '100%'],
            areaBgGradations: options.areaBgGradations || ['0%', '75%'],
            linesBgGradations: options.linesBgGradations || ['0%', '45%', '65%', '100%'],
            leftShadowBgGradations: options.leftShadowBgGradations || ['20%', '50%', '100%'],
            rightShadowBgGradations: options.rightShadowBgGradations || ['0%', '50%', '80%'],
            circleShadowColor: options.circleShadowColor || select(container).attr('data-circle-shadow') || '#00dac0',
            circleShadowHoverColor: options.circleShadowHoverColor || select(container).attr('data-circle-shadow-hover') || '#00dac0',
            extendedTooltip: options.extendedTooltip || false
        };

        if (typeof data === 'string') {
            json(`${data}.json`, (err, jsonData) => {
                if (err) console.log('JSON load error:',  err.currentTarget.statusText);
                else {
                    this.data = jsonData;
                    this[draw](jsonData);
                }
            });
        } else {
            this[draw](data);
        }

    }

    /**
     * Private method for first draw graph.
     * @param {Array} loadedData array with graph elements.
     */
    [draw](loadedData) {
        this.svg = new Svg(this.container, loadedData);
        this.pathLine = new PathLine(this.container, loadedData);
        this.pathArea = new PathArea(this.container, loadedData);
        this.circles = new Circles(this.container, loadedData);
        this.tooltips =  new Tooltips(this.container, loadedData);
        this.yAxis = new YAxis(this.container, loadedData);
        this.xAxis = new XAxis(this.container, loadedData);
        this.lines = new Lines(this.container, loadedData);
        this.shadowRects = new ShadowRect(this.container, loadedData);

        this[prepareData]();
        this.svg.draw();
        this[setVisualization]();
        this.shadowRects.draw();

        if (this.options.removeElements) {
            this.activeElements = this.elements.filter(val => !this.options.removeElements.includes(val));
            
            this.activeElements.forEach((i) => {
                this[i].draw();
            });
        } else {
            this.elements.forEach((i) => {
                this[i].draw();
            });
        }

        setHover(this.options.hoverActiveElements, this.options.clickActiveElements, this.container, loadedData, this.options.extendedTooltip);

        window.addEventListener('resize', () => {
            this.svg.update();
            this.pathLine.resize();
            this.pathArea.resize();
            this.lines.update();
            this.shadowRects.update();
            this.yAxis.update();
            this.xAxis.update();
            this.circles.update();
            this.tooltips.update();
            setHover(this.options.hoverActiveElements, this.options.clickActiveElements, this.container, loadedData, this.options.extendedTooltip);
        });
    }

    /**
     * Private method for update graph data. Get parameters from public method update.
     */
    [updateData](data, options, oldElements) {
        this.svg.updateData(data);
        this.shadowRects.updateData(data);
        
        if (!options) {
            this.activeElements = this.elements;
        }

        if (oldElements.length > 0) {
            this.newElements = this.activeElements.filter(val => !oldElements.includes(val));
            this.newElements.forEach((i) => {
                this[i].draw();
            });
        }

        this.activeElements.forEach((i) => {
            this[i].updateData(data);
        });
    }

    /**
     * Private method for set gradients and filters on main graph SVG.
     */
    [setVisualization]() {
        getGradient('path-line-bg', 'linearGradient', this.options.pathBgGradations, null, false, 'vertical');
        getGradient('path-area-bg', 'linearGradient', this.options.areaBgGradations, null, true, 'vertical');
        getGradient('lines-tick-bg', 'linearGradient', this.options.linesBgGradations, null, true, 'vertical');
        getGradient('left-shadow-bg', 'linearGradient', this.options.leftShadowBgGradations, null, false, 'horizontal');
        getGradient('right-shadow-bg', 'linearGradient', this.options.rightShadowBgGradations, null, false, 'horizontal');

        getFilter(
            'circle-shadow',
            'offset',
            {
                color: this.options.circleShadowColor,
                height: '130%',
                width: '130%',
                blur: 1,
                dx: 0,
                dy: 1
            }
        );
        getFilter(
            'circle-shadow-hover',
            'blur',
            {
                color: this.options.circleShadowHoverColor,
                height: '250%',
                width: '250%',
                blur: 5,
                x: '-70%',
                y: '-70%'
            }
        );
    }

    /**
     * Public method for update graph with new data and options.
     * 
     * @param data Can be {Array} with new data or {String} with JSON file name;
     * @param options {Object} Object with options
     * @param options.removeElements {Array} List of removed elements from graph.
     * @param options.hoverActiveElements {Array} List of elements where should add hover.
     * @param options.clickActiveElements {Array} List of elements where should add active on click.
     */
    update(data, options) {
        this.options = {
            removeElements: options.removeElements || null,
            hoverActiveElements: options.hoverActiveElements || ['lines', 'xAxis', 'circles'],
            clickActiveElements: options.clickActiveElements || ['lines', 'circles'],
            extendedTooltip: options.extendedTooltip || false
        };

        this.oldActiveElements = this.activeElements;
        
        if (this.options.removeElements) {
            this.options.removeElements.forEach((i) => {
                this[i].remove();
            });

            this.activeElements = this.elements.filter(val => !this.options.removeElements.includes(val));
        }

        if (typeof data === 'string') {
            json(`${data}.json`, (err, jsonData) => {
                if (err) console.log('JSON load error:',  err.currentTarget.statusText);
                else {
                    this.data = jsonData;
                    this[prepareData]();
                    this[updateData](this.data, this.options, this.oldActiveElements);
                    setHover(this.options.hoverActiveElements, this.options.clickActiveElements, this.container, this.data, this.options.extendedTooltip);
                }
            });
        } else {
            this.data = data;
            this[prepareData]();
            this[updateData](this.data, this.options, this.oldActiveElements);
            setHover(this.options.hoverActiveElements, this.options.clickActiveElements, this.container, this.data, this.options.extendedTooltip);
        }
    }

    /**
     * Public method for destroy and remove graph from DOM.
     */
    destroy() {
        this.elements.forEach((i) => {
            this[i].remove();
        });
        this.svg.remove();
    }

    /**
     * Function prepareData append to array of data two fake elements on start and end.
     * This hack needed for design graph.
     * @returns {Array} Data with fake elements.
     */
    [prepareData]() {
        this.data.unshift({
            name: 'tempData',
            value: this.data[0].value >= 90 ? this.data[0].value - 10 : this.data[0].value + 10
        });

        this.data.push({
            name: 'tempData2',
            value: this.data[this.data.length - 1].value >= 90 ? this.data[this.data.length - 1].value - 10 : this.data[this.data.length - 1].value + 10
        });

        return this.data;
    }

    /**
     * Function getRandomData generate and return array of random data.
     *
     * @param {number} quantity Number of generated elements.
     * If this parameter NaN, function will return random quantity elements.
     * @returns {Array} Generated data.
     */
    getRandomData(quantity = 5) {
        let randomData = [];

        if (isNaN(quantity)) {
            quantity = Math.round(Math.random() * (15 - 1) + 1);
        }

        for (var i = 0; i < quantity; i++) {
            randomData.push({
                name: Math.random().toString(36).substr(2, 10),
                value: Math.round(Math.random() * (100 - 1) + 1)
            });
        }

        return randomData;
    }

}

window.Graph = Graph;
