import { select, selectAll, event } from 'd3-selection';
import 'd3-transition';
import * as MobileDetect from 'mobile-detect';
import {
    Width,
    getHeight,
    getX,
    getY,
    setArea,
    setPath,
    GetStartData,
    setYAxis,
    setXAxis,
    getZoom,
    removeElements,
    getPosition,
    getTransform
} from './attributes';

/**
 * Class for draw main SVG element.
 */
export class Svg {
    /**
     * @param {String} container - class name of graph parent container.
     * @param {Array} data - array with graph data.
     */
    constructor(container, data) {
        this.container = container;
        this.data = data;
        this.width = new Width(container, data);
    }

    draw() {
        let svg = select(this.container)
            .append('svg')
            .attr('id', 'mainGraphSvg');

        let scrollable = svg.append('g')
            .attr('class', 'scrollable');

        scrollable.append('g');

        svg.append('g')
            .attr('class', 'not-scrollable');

        this.update();
    }

    update() {
        select('#mainGraphSvg')
            .attr('width', this.width.get())
            .attr('height', getHeight(this.container));

        //Added end disable zoom events.
        addZoom(this.width, this.data);

        //Reset translate on resize.
        select('.scrollable g')
            .attr('transform', 'translate(0,0)');
        select('.scrollable')
            .attr('transform', 'translate(0,0)');
    }

    remove() {
        removeActive(this.data);
        select('#mainGraphSvg')
            .transition()
            .delay(1500)
            .remove();
    }

    /**
     * Method for update graph data.
     *
     * @param {Array} data - array with new data.
     */
    updateData(data) {
        this.data = data;
        this.width = new Width(this.container, data);
        this.update();
    }
}

/**
 * Class for draw PathLine graph element.
 */
export class PathLine {
    /**
     * @param {String} container - class name of graph parent container.
     * @param {Array} data - array with graph data.
     */
    constructor(container, data) {
        this.container = container;
        this.data = data;
        this.width = new Width(container, data);
    }

    draw() {
        select('#mainGraphSvg g.scrollable g')
            .append('path')
            .attr('class', 'path-line')
            .attr('stroke-width', '4')
            .attr('stroke-linecap', 'round')
            .attr('fill', 'none')
            .attr('transform', 'translate(0, 50)');
        
        this.update();
    }

    update(transition = 1500) {
        select('.path-line')
            .attr('d', setPath(
                new GetStartData(this.data).getData(),
                this.width.getExtended(),
                getHeight(this.container) - 150
            )(new GetStartData(this.data).getData()))
            .transition()
            .duration(transition)
            .attr('stroke', 'url(#path-line-bg)')
            .style('opacity', 1)
            .attr('d', setPath(
                this.data,
                this.width.getExtended(),
                getHeight(this.container) - 150
            )(this.data));
    }

    resize() {
        select('.path-line')
            .attr('d', setPath(
                this.data,
                this.width.getExtended(),
                getHeight(this.container) - 150
            )(this.data));
    }

    /**
     * Method for update PathLine data.
     *
     * @param {Array} data - array with new data.
     */
    updateData(data) {
        this.data =  data;
        this.width = new Width(this.container, data);
        this.update(1000);
    }

    remove() {
        select('.path-line')
            .transition()
            .duration(1000)
            .style('opacity', 0);
    }
}

/**
 * Class for draw PathArea graph element.
 */
export class PathArea {
    /**
     * @param {String} container - class name of graph parent container.
     * @param {Array} data - array with graph data.
     */
    constructor(container, data) {
        this.container = container;
        this.data = data;
        this.width = new Width(container, data);
    }

    draw() {
        select('#mainGraphSvg g.scrollable g')
            .append('path')
            .attr('class', 'path-area')
            .attr('transform', 'translate(0, 50)');
        this.update();
    }

    update(transition = 1500) {
        select('.path-area')
            .attr('d', setArea(
                new GetStartData(this.data).getData(),
                this.width.getExtended(),
                getHeight(this.container) - 150
            )(new GetStartData(this.data).getData()))
            .transition()
            .duration(transition)
            .style('fill', 'url(#path-area-bg)')
            .style('opacity', 1)
            .attr('d', setArea(
                this.data,
                this.width.getExtended(),
                getHeight(this.container) - 150
            )(this.data));
    }

    resize() {
        select('.path-area')
            .attr('d', setArea(
                this.data,
                this.width.getExtended(),
                getHeight(this.container) - 150
            )(this.data));
    }

    /**
     * Method for update PathArea data.
     *
     * @param {Array} data - array with new data.
     */
    updateData(data) {
        this.data =  data;
        this.width = new Width(this.container, data);
        this.update(1000);
    }

    remove() {
        select('.path-area')
            .transition()
            .duration(1000)
            .style('opacity', 0);
    }
}

/**
 * Class for draw Circles graph elements.
 */
export class Circles {
    /**
     * @param {String} container - class name of graph parent container.
     * @param {Array} data - array with graph data.
     */
    constructor(container, data) {
        this.container = container;
        this.data = data;
        this.width = new Width(container, data);
    }

    append() {
        select('#mainGraphSvg g.scrollable g')
            .append('g')
            .attr('class', 'circles')
            .attr('transform', 'translate(0, 50)');
    }

    draw() {
        this.append();
        this.drawCircles();
    }

    /**
     * Method for draw circles.
     *
     * @param {String} className - class name of circle
     * @param {Boolean} [onlyRemove] - true to remove circles by class name.
     */
    drawCircle(className, onlyRemove = false) {
        if (onlyRemove) {
            selectAll(`.${className}`).remove();
        } else {
            selectAll(`.${className}`).remove();
            select('g.circles')
                .selectAll(`.${className}`)
                .data(this.data)
                .enter().append('circle')
                .attr('class', (d, i) => {
                    return `${className} ${className}-${i}`;
                })
                .attr('r', 0)
                .attr('cx', (d, i) => { return getX(this.data, this.width.getExtended())(i); })
                .attr('cy', (d) => {
                    return getY(getHeight(this.container) - 150)(d.value);
                });

            removeElements(`.circles .${className}`);
        }
    }

    drawCircles(delay = 1700) {
        if (this.width.get() <= 1024) {
            this.drawCircle('circle-mobile');
        } else {
            this.drawCircle('circle-mobile', true);
        }
        this.drawCircle('helper-circle-small');
        this.drawCircle('helper-circle-big');
        this.drawCircle('circle');

        selectAll('.circles .circle')
            .transition()
            .delay(delay)
            .duration(0)
            .attr('r', 7)
            .attr('opacity', 1);
    }

    update() {
        this.drawCircles(0);
    }

    /**
     * Method for update circles data.
     *
     * @param {Array} data - array with new data.
     */
    updateData(data) {
        this.data =  data;
        this.width = new Width(this.container, data);
        this.drawCircles(1200);
    }

    remove() {
        selectAll('.circles').remove();
    }
}

/**
 * Class for draw Tooltip graph elements.
 */
export class Tooltips {
    /**
     * @param {String} container - class name of graph parent container.
     * @param {Array} data - array with graph data.
     */
    constructor(container, data) {
        this.container = container;
        this.data = data;
        this.width = new Width(container, data);
    }

    append() {
        select('#mainGraphSvg g.scrollable g')
            .append('g')
            .attr('class', 'tooltips')
            .attr('transform', 'translate(0, 50)');
    }

    draw() {
        this.append();
        this.drawTooltip();
    }

    drawTooltip() {
        selectAll('.tooltip').remove();
        select('g.tooltips')
            .selectAll(`.tooltip`)
            .data(this.data)
            .enter()
            .append('text')
            .attr('class', (d, i) => {
                return 'tooltip tooltip-' + i;
            })
            .html((d) => {
                return d.value + '%';
            })
            .attr('x', (d, i) => { return getX(this.data, this.width.getExtended())(i); })
            .attr('y', (d) => {
                return getY(getHeight(this.container) - 150)(d.value);
            })
            .attr('dx', '-10px')
            .attr('dy', (d) => {
                return d.value >= 90 ? '50px' : '-50px';
            });
        removeElements(`.tooltip`);
    }

    update() {
        this.drawTooltip();
    }

    /**
     * Method for update Tooltip data.
     *
     * @param {Array} data - array with new data.
     */
    updateData(data) {
        this.data = data;
        this.width = new Width(this.container, data);
        this.drawTooltip();
    }

    remove() {
        select('.tooltips').remove();
    }
}

/**
 * Class for draw Y Axis.
 */
export class YAxis {
    /**
     * @param {String} container - class name of graph parent container.
     * @param {Array} data - array with graph data.
     */
    constructor(container, data) {
        this.container = container;
        this.data = data;
    }

    draw() {
        select('#mainGraphSvg g.not-scrollable')
            .append('g')
            .attr('class', 'y axis')
            .attr('transform', 'translate(-50, 50)')
            .transition()
            .duration(1000)
            .attr('transform', 'translate(5, 50)')
            .call(setYAxis(getHeight(this.container) - 150));

        selectAll('.y.axis line').remove();

        selectAll('.y.axis .tick')
            .append('circle')
            .attr('class', 'axis-point')
            .attr('r', 2.5);

        selectAll('.y.axis text')
            .attr('dx', '5px');

        select('.y.axis path').remove();
        select('.y.axis')
            .append('line')
            .attr('class', 'line')
            .attr('y2', getHeight(this.container))
            .attr('y1', 0)
            .attr('x1', 0)
            .attr('x2', 0)
            .attr('transform', 'translate(0, -50)');
    }

    /**
     * Method to update Y Axis.
     *
     * @param {Boolean} [animate] - true for animated update.
     */
    update(animate = false) {
        let yAxis = select('.y.axis')
            .call(setYAxis(getHeight(this.container) - 150));

        if (animate) {
            yAxis.attr('transform', 'translate(-50, 50)')
                .transition()
                .duration(1000)
                .attr('transform', 'translate(5, 50)')
        } else {
            yAxis.attr('transform', 'translate(5, 50)')
                .attr('y2', getHeight(this.container));
        }

        select('.y.axis path').remove();
    }
    
    updateData() {
        this.update(true);
    }

    remove() {
        select('.y.axis')
            .transition()
            .duration(1000)
            .attr('transform', 'translate(-50, 50)')
            .remove();
    }
}

/**
 * Class for draw X Axis.
 */
export class XAxis {
    /**
     * @param {String} container - class name of graph parent container.
     * @param {Array} data - array with graph data.
     */
    constructor(container, data) {
        this.container = container;
        this.data = data;
        this.width = new Width(container, data);
    }

    append() {
        select('#mainGraphSvg g.scrollable g')
            .append('g')
            .attr('class', 'x axis');
    }

    /**
     * Method to update X Axis.
     *
     * @param {Boolean} [animate] - true for animated update.
     */
    drawAxis(animate = false) {
        let xAxis = select('.x.axis')
            .call(setXAxis(this.data, this.width.getExtended()));

        if (animate) {
            xAxis.attr('transform', 'translate(0' + ',' + (getHeight(this.container) + 50) + ')')
                .transition()
                .duration(1000)
                .attr('transform', 'translate(0' + ',' + (getHeight(this.container) - 60) + ')');
        } else {
            xAxis.attr('transform', 'translate(0' + ',' + (getHeight(this.container) - 60) + ')');
        }

        selectAll('.x.axis line').remove();
        selectAll('.x.axis path').remove();
        selectAll('.x.axis .tick circle').remove();
        selectAll('.x.axis text')
            .attr('dy', '20px');
        selectAll('.x.axis .tick')
            .attr('class', (d, i) => {
                return `tick tick-${i}`;
            })
            .append('circle')
            .attr('class', 'axis-point')
            .attr('r', 2.5);
        removeElements('.x.axis g');
    }

    draw() {
        this.append();
        this.drawAxis(true);
    }

    update() {
        this.drawAxis();
    }

    /**
     * Method for update X Axis data.
     *
     * @param {Array} data - array with new data.
     */
    updateData(data) {
        this.data = data;
        this.width = new Width(this.container, data);
        this.drawAxis(true);
    }

    remove() {
        select('.x.axis')
            .transition()
            .duration(1000)
            .attr('transform', 'translate(0' + ',' + (getHeight(this.container) + 100) + ')')
            .remove();
    }
}

/**
 * Class for draw Lines graph elements.
 */
export class Lines {
    /**
     * @param {String} container - class name of graph parent container.
     * @param {Array} data - array with graph data.
     */
    constructor(container, data) {
        this.container = container;
        this.data = data;
        this.width = new Width(container, data);
    }

    append() {
        select('#mainGraphSvg g.scrollable g')
            .append('g')
            .attr('class', 'lines');
    }

    draw() {
        this.append();
        this.drawLine('lines-tick', true);
        this.drawLine('lines-tick-hover', true);
    }

    /**
     * Method to draw Line by class name.
     * @param {String} className - class name of line element.
     * @param {Boolean} [animate] - true to animated draw line element.
     */
    drawLine(className, animate = false) {
        selectAll(`.${className}`).remove();

        let lines = select('g.lines')
            .selectAll(`.${className}`)
            .data(this.data)
            .enter()
            .append('line');

        if (animate) {
            lines.style('opacity', 0)
                .transition()
                .duration(500)
                .style('opacity', .3);
        }

        lines.attr('class', (d, i) => {
                return `${className} ${className}-${i}`;
            })
            .attr('y2', getHeight(this.container))
            .attr('y1', 0)
            .attr('x1', (d, i) => {return getX(this.data, this.width.getExtended())(i)})
            .attr('x2', (d, i) => {return getX(this.data, this.width.getExtended())(i)});
        removeElements(`.lines .${className}`);
    }

    update() {
        this.drawLine('lines-tick');
        this.drawLine('lines-tick-hover');
    }

    /**
     * Method for update Lines data.
     *
     * @param {Array} data - array with new data.
     */
    updateData(data) {
        this.data = data;
        this.width = new Width(this.container, data);
        this.drawLine('lines-tick', true);
        this.drawLine('lines-tick-hover', true);
    }

    remove() {
        selectAll('.lines line')
            .transition()
            .duration(1000)
            .style('stroke-opacity', 0);

        select('.lines')
            .transition()
            .delay(1000)
            .duration(0)
            .remove();
    }
}

/**
 * Class for draw Shadow Rectangles on graph edges.
 */
export class ShadowRect {
    /**
     * @param {String} container - class name of graph parent container.
     * @param {Array} data - array with graph data.
     */
    constructor(container, data) {
        this.container = container;
        this.data = data;
        this.width = new Width(container, data);
    }

    append() {
        select('#mainGraphSvg g.not-scrollable')
            .append('g')
            .attr('class', 'shadowRects');
    }

    draw() {
        this.append();
        this.drawRects();
    }

    drawRects() {
        select('.leftShadowRect').remove();
        select('.rightShadowRect').remove();

        select('.shadowRects')
            .append('rect')
            .attr('class', 'leftShadowRect')
            .attr('width', getX(this.data, this.width.getExtended())(1) / 2)
            .attr('height', getHeight(this.container))
            .attr('x', -5)
            .attr('y', 0)
            .attr('fill', 'url(#left-shadow-bg)');

        select('.shadowRects')
            .append('rect')
            .attr('class', 'rightShadowRect')
            .attr('width', getX(this.data, this.width.getExtended())(1) / 2)
            .attr('height', getHeight(this.container))
            .attr('x', this.width.get() - getX(this.data, this.width.getExtended())(1) / 2)
            .attr('y', 0)
            .attr('fill', 'url(#right-shadow-bg)');
    }

    update() {
        this.drawRects();
    }

    /**
     * Method for update ShadowRect data.
     *
     * @param {Array} data - array with new data.
     */
    updateData(data) {
        this.data = data;
        this.width = new Width(this.container, data);
        this.drawRects();
    }
}

/**
 * Function for set hover on graph elements.
 *
 * @param {Array} elements - array of elements to set hover.
 * @param {Array} activeElements - array of elements to set active on click.
 * @param {String} container - class name of graph parent container.
 * @param {Array} data - array with graph data.
 * @param {Boolean} tooltipActive - true to add extended text tooltip.
 */
export function setHover(elements, activeElements, container, data, tooltipActive) {
    removeActive(data);
    select('#mainGraphSvg').attr('data-active-element', -1);
    let width = new Width(container, data);
    let click = false;
    let tooltip = new TextTooltip(container, tooltipActive);
    let device = new MobileDetect.default(window.navigator.userAgent).mobile();


    if (elements.length !== 0) {
        elements.forEach((item) => {
            let active = false;
            let className;

            if (activeElements.length !== 0) {
                active = activeElements.includes(item);
            }

            switch(item) {
                case 'lines':
                    className = '.lines-tick-hover';
                    break;

                case 'circles':
                    className = '.circle';
                    
                    selectAll('.circle-mobile')
                        .attr('r', 25)
                        .style('display', () => {
                            if (!device) {
                                return 'none';
                            }
                        })
                        .attr('fill', 'transparent')
                        .on('mouseover', (d, i) => {
                            onOver(i + 1);
                            tooltip.setHover(className, d, i, data);
                            setTimeout(() => {
                                tooltip.setActive();
                            }, 100);
                        })
                        .on('mouseout', (d, i) => {
                            onOut(i + 1);
                            tooltip.removeHover();
                            tooltip.removeActive();
                        });
                    break;

                case 'xAxis':
                    className = '.x.axis .tick';
                    break;
            }

            selectAll(className)
                .on('mouseover', (d, i) => {
                    onOver(i + 1);
                    if (active) {
                        removeZoom();
                    }
                    tooltip.setHover(className, d, i, data);
                })
                .on('mouseout', (d, i) => {
                    if (active) {
                        addZoom(width, data);

                        if (!click) {
                            onOut(i + 1);
                        }
                        if (Number(select('#mainGraphSvg').attr('data-active-element')) !== i) {
                            onOut(i + 1);
                        }
                    } else {
                        onOut(i + 1);
                    }
                    tooltip.removeHover();
                })
                .on('click', (d, i) => {
                    click = true;
                    
                    if (Number(select('#mainGraphSvg').attr('data-active-element')) === i) {
                        onOut(i + 1);
                        select('#mainGraphSvg').attr('data-active-element', -1);
                        tooltip.removeActive();
                    } else {
                        if (active) {
                            data.forEach((item, i) => {
                                onOut(i);
                            });

                            if (activeElements) {
                                onOver(i + 1);
                            }
                        }
                        tooltip.setActive();
                        select('#mainGraphSvg').attr('data-active-element', i);
                    }
                    addZoom(width, data);
                });
        });
        
    }
}

/**
 * Class for draw extended TextTooltip.
 */
class TextTooltip {
    /**
     * @param {String} container - class name of graph parent container.
     * @param {Boolean} activate - true to draw extended text tooltip.
     */
    constructor(container, activate) {
        this.container = container;
        this.activate = activate;
        this.drawed = false;
        this.hovered = false;
    }
    
    draw(className, d, i) {
        this.drawed = true;

        if (Object.keys(d).length > 2 && this.activate) {
            select(this.container)
                .style('position', 'relative')
                .append('div')
                .attr('class', 'text-tooltip')
                .style('left', () => {
                    if (className === '.circle') {
                        return getPosition(this.container, className + '-' + (i + 1)).left + 22 + 'px';
                    } else {
                        return getPosition(this.container, className + '-' + (i + 1)).left + 15 + 'px';
                    }
                })
                .html(`
                    <div class="first-tooltip-helper">
                        <span class="year">${d.year}</span>
                    </div>
                    <div class="second-tooltip-helper">
                        <div class="second-tooltip-helper-container">
                            <h2 class="title">${d.title + d.name}</h2>
                            <span class="subtitle">${d.subtitle}</span>
                            <p class="text">${d.text}</p>
                        </div>
                    </div>
                `);

            let tooltipWidth = select('.second-tooltip-helper').node().getBoundingClientRect().width;
            let hoverPosition = getPosition(this.container, className + '-' + (i + 1)).right * -1;

            if (hoverPosition <= tooltipWidth) {
                select('.text-tooltip').classed('left', true);
            } else {
                select('.text-tooltip').classed('left', false);
            }

        }
    }

    clear() {
        this.drawed = false;
        selectAll('.text-tooltip').remove();
    }
    
    setHover(className, d, i, data) {
        if (Number(select('#mainGraphSvg').attr('data-active-element')) !== i) {
            removeActive(data);
            onOver(i + 1);
            this.clear();
            this.draw(className, d, i);
        }

        if (this.drawed) {
            this.hovered = true;

            select('.first-tooltip-helper')
                .transition()
                .duration(400)
                .style('opacity', 1);
        }
    }

    removeHover() {
        if (this.hovered) {
            this.hovered = false;

            select('.first-tooltip-helper')
                .transition()
                .duration(400)
                .style('opacity', 0);
        }
    }

    setActive() {
        if (this.drawed) {
            select('.text-tooltip').classed('active', true);
        }
    }

    removeActive() {
        if (this.drawed) {
            select('.text-tooltip').classed('active', false);
        }
    }
}

/**
 * Function to add hover effect on elements.
 *
 * @param {String|Number} element - number of element to add hover effect.
 */
function onOver(element) {
    select(`.helper-circle-big-${element}`)
        .transition()
        .duration(400)
        .attr('r', 28);
    select(`.helper-circle-small-${element}`)
        .transition()
        .duration(400)
        .attr('r', 18);
    select(`.lines-tick-${element}`).classed('active', true);
    select(`.x.axis .tick-${element}`).classed('active', true);
    select(`.tooltip-${element}`).classed('active', true);
}

/**
 * Function to remove hover effect on element.
 *
 * @param {String|Number} element - number of element to remove hover effect.
 */
function onOut(element) {
    select(`.helper-circle-small-${element}`)
        .transition()
        .duration(400)
        .attr('r', 0);
    select(`.helper-circle-big-${element}`)
        .transition()
        .duration(400)
        .attr('r', 0);
    select(`.lines-tick-${element}`).classed('active', false);
    select(`.x.axis .tick-${element}`).classed('active', false);
    select(`.tooltip-${element}`).classed('active', false);
}

/**
 * Function to add zoom behavior to graph.
 *
 * @param {Width} width - Width class.
 * @param {Array} data - array with graph data.
 */
function addZoom(width, data) {
    select('#mainGraphSvg')
        .call(getZoom(() => {
            let revertedWidth = (width.getExtended() - width.get()) * -1;

            let dx = event.transform.x;

            if (dx + getTransform('.scrollable g')[0] < revertedWidth) {
                event.transform.x = getTransform('.scrollable')[0];
                return false;
            } else if (dx + getTransform('.scrollable g')[0] > 0) {
                event.transform.x = getTransform('.scrollable')[0];
                return false;
            }

            select('.scrollable').attr('transform', 'translate(' + dx + ',0)');

            removeActive(data);
        }))
        .on('dblclick.zoom', null)
        .on('wheel.zoom', () => {
            let revertedWidth = (width.getExtended() - width.get()) * -1;
            let dx = null;

            if (event.deltaX !== 0 && event.deltaX !== -0) {
                dx = (event.deltaX * -1) + getTransform('.scrollable g')[0];
            } else {
                dx = (event.deltaY * -1) + getTransform('.scrollable g')[0];
            }

            if (dx + getTransform('.scrollable')[0] < revertedWidth) {
                return false;
            } else if (dx + getTransform('.scrollable')[0] > 0) {
                return false;
            }

            select('.scrollable g').attr('transform', 'translate(' + dx + ',0)');

            removeActive(data);
        });
}

/**
 * Function to remove zoom behavior from graph.
 */
function removeZoom() {
    select('#mainGraphSvg')
        .on('.zoom', null);
}

/**
 * Function to remove all active hover effects from elements.
 * @param {Array} data - array with graph data.
 */
function removeActive(data) {
    select('#mainGraphSvg').attr('data-active-element', -1);

    if (!select('.text-tooltip').empty()) {
        selectAll('.text-tooltip').remove();
    }

    for (var i = 0; i < data.length; i++) {
        onOut(i + 1);
    }
}
