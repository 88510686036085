import { select } from 'd3-selection';

/**
 * Function to generate SVG gradient.
 *
 * @param {String} id - gradient id name.
 * @param {String} type - type of gradient. Can be 'linearGradient' or 'radialGradient'.
 * @param {Array} gradations - array of gradient gradations.
 * @param {Object|null} coordinates - radial gradient coordinates, null for 'linearGradient'.
 * @param {Object} coordinates.cx - cx radial gradient coordinates.
 * @param {Object} coordinates.cy - cy radial gradient coordinates.
 * @param {Object} coordinates.r - radius of radial gradient.
 * @param {Boolean} units - if true set gradient units to 'userSpaceOnUse'.
 * @param {String} direction - direction of gradient. Can be 'vertical' or 'horizontal'.
 */
export function getGradient(id, type, gradations, coordinates, units, direction) {
    if (select('defs.gradients').empty()) {
        select('#mainGraphSvg')
            .append('defs')
            .attr('class', 'gradients');
    }

    let gradient = select('defs.gradients')
        .append(type)
        .attr('id', id);

    if (units) {
        gradient.attr('gradientUnits', 'userSpaceOnUse');
    }

    if (type === 'linearGradient') {
        gradient.attr('x1', '0%')
            .attr('y1', '0%');

        if (direction === 'vertical') {
            gradient.attr('x2', '0%')
                .attr('y2', '100%');
        } else if (direction === 'horizontal') {
            gradient.attr('x2', '100%')
                .attr('y2', '0%');
        }

    } else if (type === 'radialGradient') {
        gradient.attr('cx', coordinates.cx)
            .attr('cy', coordinates.cy)
            .attr('r', coordinates.r);
    }

    gradations.forEach(function (i) {
        gradient.append('stop')
            .attr('offset', i);
    });
}

/**
 * Function to convert HEX color to RGB.
 *
 * @param {String} hex - color in HEX.
 * @returns {Object|null} - object with RGB color.
 */
function hexToRgb(hex) {
    let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function(m, r, g, b) {
        return r + r + g + g + b + b;
    });

    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

/**
 * Function to generate SVG filter.
 *
 * @param {String} id - filter id name.
 * @param {String} type - type of filter. Can be 'offset' or 'blur'.
 * @param {Object} attr - object with filter options.
 * @param {String} attr.color - filter color.
 * @param {String} attr.height - filter height.
 * @param {String} attr.width - filter width.
 * @param {Number} attr.blur - filter blur.
 * @param {Number} [attr.dx] - filter dx position. Optional for 'blur' type of filter.
 * @param {Number} [attr.dy] - filter dy position. Optional for 'blur' type of filter.
 * @param {String} [attr.x] - filter x position. Optional for 'offset' type of filter.
 * @param {String} [attr.y] - filter y position. Optional for 'offset' type of filter.
 */
export function getFilter(id, type, attr) {
    if (select('defs.filters').empty()) {
        select('#mainGraphSvg')
            .append('defs')
            .attr('class', 'filters');
    }

    let filter = select('defs.filters').append('filter').attr('id', id);

    let color = hexToRgb(attr.color);

    let matrix = `0 0 0 ${color.r/256} 0 0 0 0 0 ${color.g/256} 0 0 0 0 ${color.b/256} 0 0 0 1 0`;

    filter.attr('height', attr.height).attr('width', attr.width);

    if (type === 'blur') {
        filter.attr('x', attr.x).attr('y', attr.y);
    }

    filter.append('feColorMatrix')
        .attr('type', 'matrix')
        .attr('values', matrix);

    filter.append('feGaussianBlur')
        .attr('stdDeviation', attr.blur)
        .attr('result', 'coloredBlur');

    filter.append("feOffset")
        .attr("in", "coloredBlur")
        .attr("dx", attr.dx)
        .attr("dy", attr.dy)
        .attr("result", "offsetBlur");

    let feMerge = filter.append('feMerge');

    if (type === 'offset') {
        feMerge.append("feMergeNode")
            .attr("in", "offsetBlur");
    } else if (type === 'blur') {
        feMerge.append('feMergeNode')
            .attr('in', 'coloredBlur');
    }

    feMerge.append('feMergeNode')
        .attr('in', 'SourceGraphic');
}